<!-- eslint-disable prettier/prettier -->
<!-- 
    页面 角色管理 (这里的Table 用了 elemnt-ui)
-->
<template>
  <div id="role-page" class="data-list-container dark-layout">
    <vx-card>
      <div class="table_height_container">
        <!-- 操作按钮 -->
        <div class="operate-container mb-3">
          <vs-button size="small" type="relief" @click="createData">新增角色</vs-button>
        </div>
        <!-- 整体表 -->
        <el-table class="table_theme" :data="roles" ref="rolesTable" v-loading="loading">
          <el-table-column type="index" width="60" label="序号">
            <template slot-scope="scope">
                <span>{{(pageOpt.currentPage - 1) * pageOpt.currentPageSize + scope.$index + 1}}</span>
            </template>
          </el-table-column>
          <el-table-column property="role_name" label="角色名称" width="200"  min-width="200" show-overflow-tooltip></el-table-column>
          <el-table-column property="role_desc" label="角色描述" min-width="200" show-overflow-tooltip></el-table-column>
          <el-table-column fixed="right" label="操作" width="120" header-align="center">
            <template slot-scope="scope">
              <el-button type="text" size="small" class="primary" @click="watchData(scope.row)">详情</el-button>
              <el-button type="text" size="small" class="primary" :disabled="scope.row.can_del !== 0" @click="editData(scope.row)">编辑</el-button>
              <el-button type="text" size="small" class="primary" :disabled="scope.row.can_del !== 0" @click="delData(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <div class="bottom mt-5 text-center">
          <el-pagination  class="pagination" layout="total, sizes, prev, pager, next"  prev-text="上一页" next-text="下一页"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageOpt.currentPage"
            :page-sizes="pageOpt.pageSizes"
            :page-size="pageOpt.currentPageSize"
            :pager-count="pageOpt.pageCount"
            :total="pageOpt.totalPageSize"
            :hide-on-single-page="pageOpt.hideSinglePage"
          ></el-pagination>
        </div>
      </div>
    </vx-card>
    <!-- 弹出框 -->
    <roleDialogCURD :dialogFormOpt="dialogFormOpt" :dialogFormData="dialogFormData" :dialogFormVisible="dialogFormVisible"  @handleDiaLogClose="handleDiaLogClose(arguments)"></roleDialogCURD>
  </div>
</template>

<script>
// 导入弹框
import roleDialogCURD from "./RoleDialogCURD";

export default {
  data() {
    return {
      // 选中数据
      selected: [],
      // 渲染数据
      roles: [],
      // 加载状态
      loading: false,
      // 弹出框状态
      dialogFormVisible: false,
      // 弹出框所需数据
      dialogFormData: {},
      // 弹出框所做的操作: CRUD
      dialogFormOpt: "",
      // 系统权限
      roleMenus: [],
      // 当前页
      pageOpt: {
        currentPage: 1,
        currentPageSize: 10,
        totalPageSize: 0,
        hideSinglePage: true,
        pageSizes: [10, 20, 30],
        pageCount: 5
      }
    };
  },
  methods: {
    // 创建数据
    createData() {
      // console.log("创建数据");
      this.dialogFormOpt = this.$CONST.OPTRATE.C;
      this.dialogFormVisible = true;
      this.dialogFormData = Object.assign(
        { roleMenus: this.roleMenus },
        { defaultCheckedKeys: [] },
        {}
      );
    },
    // 查看当前列数据
    watchData(row) {
      // console.log("查看数据", row);
      this.dialogFormOpt = this.$CONST.OPTRATE.R;
      this.dialogFormVisible = true;
      let checkedKeys = this.traversalCheckedKeys(
        this.roleMenus,
        row.function_id
      );
      let disableMenus = JSON.parse(JSON.stringify(this.roleMenus));
      this.recurisonMenu(disableMenus);
      this.dialogFormData = Object.assign(
        { roleMenus: disableMenus },
        { defaultCheckedKeys: checkedKeys },
        row
      );
    },
    // 编辑当前列数据
    editData(row) {
      // console.log("编辑数据", row);
      this.dialogFormOpt = this.$CONST.OPTRATE.U;
      this.dialogFormVisible = true;
      let checkedKeys = this.traversalCheckedKeys(
        this.roleMenus,
        row.function_id
      );
      this.dialogFormData = Object.assign(
        { roleMenus: this.roleMenus },
        { defaultCheckedKeys: checkedKeys },
        row
      );
    },
    // 删除当前列数据
    delData(row) {
      // console.log("删除数据", row);
      this.dialogFormOpt = this.$CONST.OPTRATE.D;
      this.dialogFormVisible = true;
      let checkedKeys = this.traversalCheckedKeys(
        this.roleMenus,
        row.function_id
      );
      let disableMenus = JSON.parse(JSON.stringify(this.roleMenus));
      this.recurisonMenu(disableMenus);
      this.dialogFormData = Object.assign(
        { roleMenus: disableMenus },
        { defaultCheckedKeys: checkedKeys },
        row
      );
    },
    // 列表序号
    rowClassName({ row, rowIndex }) {
      row.xh =
        rowIndex +
        1 +
        (this.pageOpt.currentPage - 1) * this.pageOpt.currentPageSize;
    },
    // 列表选中
    handleSelectionChange(val) {
      this.selected = val;
      // console.log("角色列表选中:", this.selected);
    },
    // 调整单页条数
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
      this.pageOpt.currentPageSize = val;
      this.getRoleList();
    },
    // 调整当前页数
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageOpt.currentPage = val;
      this.getRoleList();
    },
    // 当子模态框关闭回调
    handleDiaLogClose(isRefresh) {
      // console.log(isRefresh);
      this.dialogFormVisible = false;
      if (isRefresh[0]) this.getRoleList();
    },
    // 加载动画
    loadAnimation() {
      this.loading = true;
      return new Promise(resolve => {
        setTimeout(() => {
          this.loading = false;
          resolve(true);
        }, 1000);
      });
    },
    // 请求接口
    getRoleList() {
      this.loading = true;
      // 请求数据
      this.$apis
        .ODC("获取角色", {
          pageNum: this.pageOpt.currentPage,
          pageSize: this.pageOpt.currentPageSize
        })
        .then(
          result => {
            if (this.$apis.isOk(result)) {
              this.roles = result.data.roleList;
              this.pageOpt.totalPageSize = result.data.count;
            }
            setTimeout(() => {
              this.loading = false;
            }, 500);
          },
          err => {
            setTimeout(() => {
              this.loading = false;
            }, 500);
            this.$notify({
              time: 2500,
              title: "Error",
              text: this.$apis.getResMessage(err),
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger"
            });
          }
        );
    },
    // 获取权限菜单
    async getRoleMenus() {
      try {
        let result = await this.$apis.ODC("获取用户菜单", {});
        if (this.$apis.isOk(result)) {
          let menus = result.data;
          if (menus.length > 0) {
            // 移除header
            menus = menus.filter(item => {
              return !(!item.icon && !item.path && !item.children);
            });
            // 按照system_code排序
            let sortMenu = menus.sort((a, b) => {
              return Number(a["system_code"]) - Number(b["system_code"]);
            });
            // 匹配选中状态
            this.roleMenus = sortMenu;
          }
        } else {
          this.showNotify("warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
        this.showNotify("danger", err.message ? err.message : err);
      }
    },
    // 递归添加 (改动小可以使用递归)
    recurisonMenu(array) {
      for (let item of array) {
        // 改变数据格式
        item.disabled = true;
        // 判断下子集
        if (item.children) {
          this.recurisonMenu(item.children);
        }
      }
    },
    // 选取勾选的key (移除有子集未全部勾选的key) (暂时只支持3级)
    traversalCheckedKeys(meuns, functionID) {
      let defaultCheckedKeys;
      let functionArr = (defaultCheckedKeys =
        typeof functionID === "string" ? functionID.split(",") : []);
      for (let item of meuns) {
        // 判断下子集
        if (item.children) {
          for (let childrenItem of item.children) {
            if (!functionArr.includes(childrenItem.id)) {
              let fIndex = defaultCheckedKeys.findIndex(
                item => item == childrenItem.p_id
              );
              if (fIndex > -1) {
                defaultCheckedKeys.splice(fIndex, 1);
              }
            }
          }
        }
      }
      return defaultCheckedKeys;
    },
    // 提示框
    showNotify(state, text) {
      let title,
        color = "";
      switch (state) {
        case "success":
          title = "成功";
          color = "success";
          break;
        case "danger":
          title = "错误";
          color = "danger";
          break;
        case "warning":
          title = "警告";
          color = "warning";
          break;
      }
      this.$vs.notify({
        time: 2500,
        title: title,
        text: text,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: color
      });
    }
  },
  created() {
    // 请求数据
    this.getRoleList();
    this.getRoleMenus();
  },
  components: {
    roleDialogCURD
  }
};
</script>

<style lang="scss" scope="scope">
.table_height_container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 200px);
}
.el-button.is-disabled.el-button--text {
  text-decoration: line-through;
}
</style>

<template>
  <el-dialog
    customClass="customWidth"
    :visible.sync="isVisible"
    :destroy-on-close="true"
    :show-close="true"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <!-- 弹窗头 -->
    <span slot="title" class="el-dialog__title">
      <vs-avatar class="mr-3" color="primary" text="C" size="22px" />
      <h5 class="line-h-30">{{ dialogTitle }}</h5>
      <vs-divider></vs-divider>
    </span>
    <!-- 弹窗内容 -->
    <!-- 表单认证(角色名称) -->
    <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px" label-position="top">

      <!-- 登陆表单认证(角色名称) -->
      <el-form-item label="角色名称" prop="role_name">
        <el-input v-model="form.role_name"  prefix-icon="el-icon-user" placeholder="请输入角色名称"  autocomplete="off"  :disabled="isDisable" @input="e => form.role_name = validForbid(e)"></el-input>
      </el-form-item>

      <!-- 登陆表单认证(角色描述) -->
      <el-form-item label="角色描述" prop="role_desc"  class="mt-6">
        <el-input v-model="form.role_desc"  prefix-icon="el-icon-edit-outline" placeholder="请输入角色描述"  autocomplete="off" :disabled="isDisable"></el-input>
      </el-form-item>

      <!-- 表单认证(角色权限) -->
      <div class="vs-component vs-con-input-label vs-input w-full mt-6 vs-input-primary">
        <label for="" class="vs-input--label el-form-item__label">角色权限 <span style="font-size: 0.6rem;">(至少一个)</span></label>
        <tree-select
          multiple
          v-if="reFresh"
          :disabled="isDisable"
          :height="300"
          placeholderText="选择权限"
          :data="treeSelect.dataTreeList"
          :defaultProps="treeSelect.defaultProps"
          :clear="treeSelect.clearTreeSelect"
          :nodeKey="treeSelect.nodeKey"
          :checkedKeys="treeSelect.defaultCheckedKeys"
          @popoverHide="popoverHide"
          @parent-event="popoverHide"
        ></tree-select>
      </div>
     </el-form>

    <!-- 弹窗底部 -->
    <span slot="footer" class="dialog-footer">
      <vs-divider></vs-divider>
      <vs-button
        size="small"
        class="mr-5"
        type="relief"
        color="grey"
        @click="handleClose"
      >
        取 消
      </vs-button>
      <vs-button
        size="small"
        type="relief"
        color="danger"
        class="mr-5 vs-con-loading__container"
        @click="delRow"
        v-if="isDisable && this.operate == 'DELETE'"
      >
        删除
      </vs-button>
      <vs-button
        size="small"
        type="relief"
        color="primary"
        :disabled="!validateForm"
        class="vs-con-loading__container"
        @click="submitForm('ruleForm')"
        v-if="!isDisable"
      >
        确 定
      </vs-button>
    </span>
  </el-dialog>
</template>

<script>
import TreeSelect from "@/components/el-tree-select/ElTreeSelect.vue";

export default {
  props: ["dialogFormVisible", "dialogFormData", "dialogFormOpt"],
  data() {
    return {
      reFresh: true,
      dialogTitle: "",
      isVisible: this.dialogFormVisible,
      operate: this.dialogFormOpt,
      isDisable: false,
      treeSelect: {
        placeholderText: "选择权限",
        dataTreeList: [],
        clearTreeSelect: 0,
        nodeKey: "id",
        defaultCheckedKeys: [],
        defaultProps: {
          children: "children",
          label: "function_name"
        }
      },
      form: {
        role_id: "",
        role_name: "",
        role_desc: "",
        role_authorization: []
      },
      rules: {
        role_name: [
          { required: true, message: '请输入角色名称', trigger: 'blur' }
        ],
        role_desc: [
          { required: true, message: '请输入角色描述', trigger: 'blur' }
        ]
      }
    };
  },
  computed: {
    // 监听表单认证状况
    validateForm() {
      return (
        this.form.role_authorization.length > 0 &&
        !this.$cFun.isNullOrEmptyForObJ({
          role_name: this.form.role_name,
          role_desc: this.form.role_desc
        })
      );
    }
  },
  watch: {
    // 监听父组件传回来的值 - 表单值
    dialogFormData(val) {
      this.reFresh = false;
      this.form.role_id = val.role_id || "default";
      this.form.role_name = val.role_name;
      this.form.role_desc = val.role_desc;
      this.form.role_authorization =
        typeof val.function_id === "string" ? val.function_id.split(",") : [];
      this.treeSelect.dataTreeList = val.roleMenus;
      this.treeSelect.defaultCheckedKeys = val.defaultCheckedKeys;
      // 通知组件刷新了
      this.$nextTick(() => {
        this.reFresh = true;
      });
    },
    // 监听父组件传回来的值 - 开关值
    dialogFormVisible(val) {
      this.changeNavBarShadow(val);
      this.isVisible = this.dialogFormVisible;
    },
    // 监听父组件传回来的值 - CURD操作值
    dialogFormOpt(val) {
      this.operate = this.dialogFormOpt;
      switch (val) {
        case "CREATE":
          this.dialogTitle = "创建角色";
          this.isDisable = false;
          break;
        case "READ":
          this.dialogTitle = "查看角色";
          this.isDisable = true;
          break;
        case "UPDATE":
          this.dialogTitle = "更新角色";
          this.isDisable = false;
          break;
        case "DELETE":
          this.dialogTitle = "删除角色";
          this.isDisable = true;
          break;
      }
    }
  },
  methods: {
    // 表单验证
    async submitForm(formName) {
      let el = event.currentTarget;
      this.$refs[formName].validate( async (valid) => {
        if (valid) {
          await this.submit();
        } else {
          return false;
        }
      })
    },
    // 提交事件
    async submit() {
      let el = event.currentTarget;
      // 开启loading
      this.$cFun.openDomLoad(this.$vs.loading, el);
      // 判断下操作
      if (this.operate === "CREATE") {
        await this.addRole();
      } else if (this.operate === "UPDATE") {
        await this.updateRole();
      }
      // 关闭loadiing
      setTimeout(() => {
        this.$cFun.closeDomLoad(this.$vs.loading, el);
      }, 1000);
    },
    // 删除事件
    async delRow() {
      let el = event.currentTarget;
      // 开启loading
      this.$cFun.openDomLoad(this.$vs.loading, el, "danger");
      // 提交API删除角色
      await this.delRole();
      setTimeout(() => {
        this.$cFun.closeDomLoad(this.$vs.loading, el);
      }, 1000);
    },
    // 添加角色
    async addRole() {
      try {
        let params = {
          role_name: this.form.role_name,
          role_desc: this.form.role_desc,
          functionids: this.form.role_authorization
        };
        let result = await this.$apis.ODC("添加角色", params);
        if (this.$apis.isOk(result)) {
          this.showNotify("success", this.$apis.getResMessage(result));
          this.handleClose(this, true);
        } else {
          this.showNotify("warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
        this.showNotify("danger", err.message ? err.message : err);
      }
    },
    // 更新角色
    async updateRole() {
      try {
        let params = {
          role_id: this.form.role_id,
          role_name: this.form.role_name,
          role_desc: this.form.role_desc,
          functionids: this.form.role_authorization
        };
        let result = await this.$apis.ODC("更新角色", params);
        if (this.$apis.isOk(result)) {
          this.showNotify("success", this.$apis.getResMessage(result));
          this.handleClose(this, true);
        } else {
          this.showNotify("warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
        this.showNotify("danger", err.message ? err.message : err);
      }
    },
    // 删除角色
    async delRole() {
      try {
        let params = {
          role_id: this.form.role_id
        };
        let result = await this.$apis.ODC("删除角色", params);
        if (this.$apis.isOk(result)) {
          this.showNotify("success", this.$apis.getResMessage(result));
          this.handleClose(this, true);
        } else {
          this.showNotify("warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
        this.showNotify("danger", err.message ? err.message : err);
      }
    },
    // 表单校验
    isValidForm() {
      return new Promise((resolve, reject) => {
        const isValid = this.$validator.validateAll();
        isValid
          .then(valid => {
            resolve(valid);
          })
          .catch(() => {
            reject(false);
          });
      });
    },
    // 关闭弹框回调
    handleClose(done, isRefresh = false) {
      this.isVisible = false;
      if (this.isVisible == false) {
        this.emptyData();
        this.$emit("handleDiaLogClose", isRefresh);
      }
    },
    // 导航栏阴影问题 组件联动 (true, false)
    changeNavBarShadow(val) {
      var dom = document.getElementById("content-area");
      if (dom) {
        if (val) {
          dom.setAttribute(
            "class",
            dom
              .getAttribute("class")
              .concat(" ")
              .concat("dialog-show-overlay")
          );
        } else {
          dom.setAttribute(
            "class",
            dom
              .getAttribute("class")
              .replace(" ", "")
              .replace("dialog-show-overlay", "")
          );
        }
      }
    },
    // 提示框
    showNotify(state, text) {
      let title,
        color = "";
      switch (state) {
        case "success":
          title = "成功";
          color = "success";
          break;
        case "danger":
          title = "错误";
          color = "danger";
          break;
        case "warning":
          title = "警告";
          color = "warning";
          break;
      }
      this.$vs.notify({
        time: 2500,
        title: title,
        text: text,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: color
      });
    },
    // 获取选中(如果取三级id 需带上 级的id)
    popoverHide(checkedIds, checkedData) {
      let role_authorization = [];
      if (checkedData != undefined && checkedData != null) {
        checkedData.forEach(item => {
          // 提取当前
          role_authorization.push(Number(item.id));
          // 取父集ID
          role_authorization.push(Number(item.p_id));
        });
      }
      // 去重，倒序, 去掉0
      role_authorization = [...new Set(role_authorization)]
        .filter(item => {
          return item != 0;
        })
        .sort((a, b) => {
          return Number(a) - Number(b);
        });
      // 提交
      this.form.role_authorization = role_authorization;
    },
    // 清空数据
    emptyData() {
      this.form.role_id = "default";
      this.form.role_authorization = [];
      this.treeSelect.dataTreeList = [];
      this.treeSelect.defaultCheckedKeys = [];
    }
  },
  destroyed() {
    this.changeNavBarShadow();
  },
  components: {
    TreeSelect
  }
};
</script>

<style lang="scss" scope="scope">
.customWidth {
  width: 35%;
  min-width: 300px;
}
// 模态框通用样式
.el-dialog__title {
  .con-vs-avatar {
    float: left;
  }
  .vs-divider {
    margin: 10px 0 !important;
  }
  .line-h-30 {
    line-height: 30px;
  }
}
// 表单通用样式
.el-dialog__body {
  padding: 0px 20px !important;
  .common-form {
    .vs-input--label {
      display: block;
      padding-bottom: 10px;
      font-size: 0.8rem;
      font-weight: 600;
    }
    .vs-input--placeholder {
      padding: 0.7rem 0.7rem 0.7rem 3rem !important;
    }
    .vs-input--icon {
      top: 10px;
    }
  }
}
</style>
